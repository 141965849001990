import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, loginAPI } from '@/api/user.js'
import { clearToken, setToken } from '@/utils/token.js'
import { getUnreadMessageCount } from '@/api/messages.js'
import router from '@/router/index.js'

export const useCommonStore = defineStore(
  'common',
  () => {
    const showLoginDialog = ref(false)
    const notifyType = ref('')
    const showNotify = ref(false)
    const notifyMessage = ref('')
    let timeoutId = null
    const isSiteSettingLoaded = ref(false)
    const siteSettings = ref({
      can_register: false,
    })

    const showNotifyMessage = (type, message) => {
      if (showNotify.value) {
        clearTimeout(timeoutId)
      }
      notifyType.value = type
      notifyMessage.value = message
      showNotify.value = true
      timeoutId = setTimeout(() => {
        showNotify.value = false
        timeoutId = null
      }, 5000)
    }
    const showErrorNotify = (message) => {
      showNotifyMessage('error', message)
    }
    const showSuccessNotify = (message) => {
      showNotifyMessage('success', message)
    }
    return {
      showLoginDialog,
      notifyType,
      showNotify,
      notifyMessage,
      showNotifyMessage,
      showErrorNotify,
      showSuccessNotify,
      siteSettings,
      isSiteSettingLoaded,
    }
  },
  {},
)
