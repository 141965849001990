import service from '@/utils/request.js'

export const getNoticeAPI = () => service.get('/common/notice')
export const getUserNoticeAPI = () => service.get('/common/user/notice')
export const getBankNameByIdAPI = (id) => service.get(`/bank/${id}/name`)
export const getBankListAPI = () => service.get('/bank')
export const getEventAPI = () => service.get('/common/event')
export const getRebateTransferMinAPI = () => service.get('/common/rebate/transfer/min')
export const useCouponAPI = (id) => service.post('/common/coupon/use', { coupon_log_id: id })
export const getTopWinnersAPI = () => service.get('/common/top_winners')
export const getSiteSettingAPI = (agent_code) => service.post('/common/site_setting', { agent_code: agent_code })
