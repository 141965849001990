<template>
  <div class="loading-overlay">
    <svg class="loading-spinner" viewBox="0 0 50 50">
      <circle class="loading-circle" cx="25" cy="25" r="20" fill="none" stroke="#ffd100" stroke-width="4" stroke-linecap="round" />
    </svg>
  </div>
</template>

<style scoped lang="scss">
.loading-overlay {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background: rgba(0, 26, 53, 0.548);
  z-index: 1;
}

.loading-spinner {
  width: 24px;
  height: 24px;
  animation: rotate 1s linear infinite;
}

.loading-circle {
  stroke-dasharray: 128;
  stroke-dashoffset: 128;
  transform-origin: center;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 128;
  }
  50% {
    stroke-dashoffset: 32;
  }
  100% {
    stroke-dashoffset: 128;
  }
}
</style>
