import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, loginAPI, refreshTokenAPI, userHeartbeat } from '@/api/user.js'
import { clearToken, getToken, setToken } from '@/utils/token.js'
import { getUnreadMessageCount } from '@/api/messages.js'
import router from '@/router/index.js'
import * as Sentry from '@sentry/vue'

export const useUserStore = defineStore(
  'user',
  () => {
    const id = ref('')
    const balance = ref('')
    const cash = ref('')
    const rebate = ref('')
    const sport_bonus = ref('')
    const live_slots_bonus = ref('')
    const coupon = ref('')
    const account = ref('')
    const name = ref('')
    const bank_id = ref('')
    const bank_num = ref('')
    const phone_num = ref('')
    const register_ts = ref('')
    const unread_count = ref(0)
    const tokenRefreshTime = ref(0)
    const vip_level = ref('')
    const vip_icon = ref('')
    const referral = ref('')

    function clearInfo() {
      id.value = ''
      balance.value = ''
      cash.value = ''
      rebate.value = ''
      sport_bonus.value = ''
      live_slots_bonus.value = ''
      coupon.value = ''
      account.value = ''
      name.value = ''
      bank_id.value = ''
      bank_num.value = ''
      phone_num.value = ''
      register_ts.value = ''
      vip_level.value = ''
      vip_icon.value = ''
      referral.value = ''
      unread_count.value = 0
    }

    function logout() {
      clearInfo()
      clearToken()
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval)
      }
      router.replace('/')
      if (router.currentRoute.value.path === '/') {
        router.go(0)
      }
    }

    function refreshToken() {
      if (localStorage.getItem('isRefreshingToken') && localStorage.getItem('isRefreshingToken') === 'true') {
        return
      }
      if (tokenRefreshTime.value + 5 * 60 * 1000 < new Date().getTime()) {
        localStorage.setItem('isRefreshingToken', 'true')
        refreshTokenAPI().then((res) => {
          setToken(res.headers.get('authorization'))
          tokenRefreshTime.value = new Date().getTime()
          localStorage.setItem('isRefreshingToken', 'false')
        })
      }
    }

    let heartbeatInterval = 0
    let unreadInterval = 0

    const updateUnreadFunction = async () => {
      if (getToken()) {
        getUnreadMessageCount().then(({ data }) => {
          unread_count.value = data.data.content
        })
      }
    }

    const heartbeatFunction = async () => {
      if (getToken()) {
        await userHeartbeat()
        await refreshToken()
      } else {
        clearInterval(heartbeatInterval)
      }
    }

    const intervalFunction = async () => {
      if (!getToken()) {
        return
      }
      await userHeartbeat()
      await updateUnreadFunction()
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval)
      }
      if (unreadInterval) {
        clearInterval(unreadInterval)
      }
      unreadInterval = setInterval(updateUnreadFunction, 5 * 60 * 1000)
      heartbeatInterval = setInterval(heartbeatFunction, 30 * 1000)
    }

    function login(data) {
      return new Promise((resolve, reject) => {
        loginAPI(data)
          .then(async (res) => {
            setToken(res.headers.get('authorization'))
            tokenRefreshTime.value = new Date().getTime()
            updateInfo().then(() => {
              router.go(0)
              resolve()
            })
            await intervalFunction()
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    function updateInfo() {
      getUnreadMessageCount().then(({ data }) => {
        unread_count.value = data.data.content
      })
      return getInfo().then(({ data }) => {
        const res = data.data.content
        id.value = res.id
        balance.value = res.balance
        cash.value = res.cash
        rebate.value = res.rebate
        sport_bonus.value = res.sport_bonus
        live_slots_bonus.value = res.live_slots_bonus
        coupon.value = res.coupon
        account.value = res.account
        name.value = res.name
        bank_id.value = res.bank_id
        bank_num.value = res.bank_num
        phone_num.value = res.phone_num
        register_ts.value = res.register_ts
        vip_level.value = res.level
        vip_icon.value = res.level_icon || 'https://w365-boproduction-storage.w365b.com/vips/icons/dyMTQukkj4Yx.webp'
        referral.value = res.referral

        Sentry.setUser({
          id: id.value,
          username: account.value,
        })
      })
    }

    return {
      id,
      balance,
      cash,
      rebate,
      sport_bonus,
      live_slots_bonus,
      coupon,
      account,
      name,
      vip_level,
      vip_icon,
      bank_id,
      bank_num,
      phone_num,
      register_ts,
      unread_count,
      tokenRefreshTime,
      login,
      logout,
      updateInfo,
      refreshToken,
      intervalFunction,
      referral,
    }
  },
  {
    persist: true,
  },
)
