<template>
  <div class="input-wrapper" @mousedown="handleWrapperMouseDown">
    <input
      ref="inputRef"
      type="text"
      :disabled="props.disabled"
      :value="displayValue"
      :placeholder="hideTitle ? displayLabel : isFocused ? displayPlaceholder : ''"
      :maxlength="maxLength === Infinity ? undefined : maxLength"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      :class="{
        'has-error': !!errorMessage,
        'has-value': !!modelValue,
        'is-focused': isFocused,
      }"
      class="ui-input"
      :style="{
        height: typeof height === 'number' ? `${height}px` : height,
        lineHeight: typeof height === 'number' ? `${height}px` : height,
      }"
      :required="required"
      :autocomplete="isPassword ? 'new-password' : 'nope'"
      :data-lpignore="true"
      autocapitalize="off"
      spellcheck="false"
    />
    <div v-if="isPassword && showPasswordToggle" class="password-toggle" @mousedown.prevent @click="togglePasswordVisibility">
      <i class="fa" style="width: 16px" :class="{ 'fa-eye-slash': hidePassword, 'fa-eye': !hidePassword, 'password-toggle-on': hidePassword }"></i>
    </div>
    <label v-if="!hideTitle && !isFocused && !modelValue" class="input-label">
      {{ displayLabel }}<span v-if="required" class="required-mark">*</span>
    </label>
    <span v-if="errorMessage && isFocused" class="error-message" v-html="formatErrorMessage"></span>
  </div>
</template>

<script setup>
import { ref, onUnmounted, computed, defineExpose, watch } from 'vue'
import { tryGetI18nText } from '@/utils/utils'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  isPassword: {
    type: Boolean,
    default: false,
  },
  height: {
    type: [String, Number],
    default: 50,
  },
  validator: {
    type: Function,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  showPasswordToggle: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: 0,
  },
  maxLength: {
    type: Number,
    default: Infinity,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'error'])
const isFocused = ref(false)

const displayValue = computed(() => {
  if (!props.isPassword) {
    return props.modelValue
  }

  // 如果是密码模式
  if (hidePassword.value) {
    // 隐藏密码时显示圆点
    return props.modelValue ? '•'.repeat(props.modelValue.length) : ''
  } else {
    // 显示密码时返回完整密码值
    return props.modelValue || ''
  }
})

const handleInput = (e) => {
  let value = e.target.value

  if (props.isPassword && hidePassword.value) {
    // 在密码模式下，需要根据长度变化来确定实际输入
    const oldLength = props.modelValue?.length || 0
    const newLength = value.length

    if (newLength > oldLength) {
      // 新增字符：取最后一个字符
      const lastChar = value.slice(-1)
      value = (props.modelValue || '') + lastChar
    } else if (newLength < oldLength) {
      // 删除字符：从原值中删除对应数量的字符
      value = props.modelValue.slice(0, newLength)
    }
  }

  if (props.maxLength !== Infinity) {
    value = value.slice(0, props.maxLength)
  }

  emit('update:modelValue', value)

  clearTimeout(validateTimer.value)
  validateTimer.value = setTimeout(() => {
    handleValidate(value)
  }, 30)
}

const handleFocus = () => {
  isFocused.value = true
}

const handleBlur = () => {
  isFocused.value = false
}

const validateTimer = ref(null)
const errorMessage = ref('')

function setErrorMsg(msg) {
  errorMessage.value = msg
}

const handleValidate = (value) => {
  if (!value) {
    setErrorMsg('')
    return
  }

  const length = value.toString().length
  if (props.minLength > 0 && length < props.minLength) {
    setErrorMsg(tryGetI18nText('input_min_length', { length: props.minLength }))
    return
  }

  if (props.validator) {
    setErrorMsg(props.validator(value))
  } else {
    setErrorMsg('')
  }
}

/**
 * 验证值是否正确
 * @returns {boolean} 是否正确
 */
function validate() {
  handleValidate(props.modelValue)
  if (errorMessage.value !== '') {
    return false
  }
  if (props.required && props.modelValue === '') {
    setErrorMsg(tryGetI18nText('form_required'))
    return false
  }
  return true
}

defineExpose({
  validate,
  setErrorMsg,
})

onUnmounted(() => {
  if (validateTimer.value) {
    clearTimeout(validateTimer.value)
  }
})

const inputRef = ref(null)

const handleWrapperMouseDown = (e) => {
  if (e.target.closest('.error-message')) {
    e.preventDefault()
    inputRef.value?.focus()
  }
}

const displayLabel = computed(() => {
  const temp = tryGetI18nText(props.label)
  console.log(temp)
  return temp
})
const displayPlaceholder = computed(() => (props.placeholder ? tryGetI18nText(props.placeholder) : ''))

const formatErrorMessage = computed(() => {
  const text = tryGetI18nText(errorMessage.value)
  if (text) {
    return text.replace(/\n/g, '<br>')
  }
  return ''
})

const hidePassword = ref(true)

const togglePasswordVisibility = () => {
  hidePassword.value = !hidePassword.value
}
</script>

<style scoped lang="scss">
$font-size: 14px;

.input-wrapper {
  position: relative;
  width: 100%;
}

.ui-input {
  width: 100%;
  padding: 0 15px;
  background: #003366;
  border-radius: 100px;
  color: white;
  font-size: $font-size;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-family: 'Work Sans';
  font-weight: 500;

  &[type='text'] {
    font-family: 'Work Sans';
    letter-spacing: normal;
  }
}

.ui-input::placeholder {
  /* font-family: "Work Sans"; */
  /* font-weight: normal; */
}

.ui-input:focus {
  outline: none;
  border-color: #003366;
}

.ui-input.has-error {
  border-color: #ff0066;
}

.input-label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  transition: all 0.2s;
  pointer-events: none;
  font-family: 'Work Sans';
  font-style: normal;
  font-size: $font-size;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-top: 1px;
}

.error-message {
  position: absolute;
  left: 0;
  top: calc(100% + 19px);
  width: 100%;
  padding: 12px 16px;
  background: #ff0051;
  border-radius: 16px;
  color: #fff;
  font-family: 'Work Sans';
  font-size: $font-size;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  min-height: 44px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  z-index: 10;
}

.error-message::after {
  content: '';
  position: absolute;
  left: 16px;
  top: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 14px solid #ff0051;
}

.required-mark {
  color: #ffffff;
  margin-left: 2px;
}

.password-toggle {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.password-toggle:hover {
  opacity: 1;
}

.password-toggle-on {
  opacity: 0.5;
}

.ui-input[type='password'],
.ui-input[type='text'] {
  padding-right: 48px;
}

/* 隐藏浏览器默认的密码切换按钮 */
.ui-input::-ms-reveal,
.ui-input::-ms-clear {
  display: none;
}

/* 针对 Chrome/Safari */
.ui-input::-webkit-contacts-auto-fill-button,
.ui-input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/* 针对最新版本的 Chrome */
.ui-input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

/* 覆盖浏览器自动填充样�� */
.ui-input:-webkit-autofill,
.ui-input:-webkit-autofill:hover,
.ui-input:-webkit-autofill:focus,
.ui-input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px #003366 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* 覆盖 Firefox 的自动填充样式 */
.ui-input:-moz-autofill,
.ui-input:-moz-autofill-preview {
  filter: none !important;
  background: #003366 !important;
  color: white !important;
}

/* 覆盖 Edge 的自动填充样式 */
.ui-input:-ms-autofill {
  background: #003366 !important;
  color: white !important;
}
</style>
